import React from "react";
import { Avatar, Menu, MenuItem, ListItemIcon, Tooltip } from "@mui/material";

//constants
import { icons, images } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { setLoginUser } from "../../../global-state/redux";

const Header = ({ pageName, icon }) => {
  const {
    dataUser: {
      user: {
        nom,
        prenom,
        roleUser: { description: role },
      },
    },
  } = useSelector((store) => store.user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header>
      {/* page title */}
      <div className="title">
        {icon}
        <h1>{pageName}</h1>
      </div>
      {/* end of page title */}
      {/* page profile information */}
      <div className="profile-informations">
        {/* notification icone */}
        <div className="notifications">
          {icons.notification}
          <div className="notif-number">
            <span>0</span>
          </div>
        </div>
        <div className="notifications">
          {icons.msg}
          <div className="notif-number">
            <span>0</span>
          </div>
        </div>
        {/* end of notifications icone */}
        {/* avatar section */}
        <Tooltip title="parametre du compte">
          <div className="avatar" onClick={handleClick}>
            <figure>
              <img src={images.avatar} alt="photo de profile" className="img" />
            </figure>
            <div className="identity">
              <span>{`${nom} ${prenom}`}</span>
              <span>{role}</span>
            </div>
          </div>
        </Tooltip>
        <MenuCompte handleClose={handleClose} open={open} anchorEl={anchorEl} />

        {/* end of avatar section */}
      </div>
      {/* end of page profile information */}
    </header>
  );
};

const MenuCompte = ({ handleClose, open, anchorEl }) => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(setLoginUser({ dataUser: [], isConnected: false }));
    localStorage.removeItem("xx_tk");
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleClose}>
        <Avatar /> Mon compte
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout();
          handleClose();
        }}
      >
        <ListItemIcon>{icons.mLogout}</ListItemIcon>
        Deconnexion
      </MenuItem>
    </Menu>
  );
};
export default Header;
