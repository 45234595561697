const urls = {
  login: "/auth/login",
  dashboard: "/dashboard",
  fournisseur: "/fournisseur",
  client: "/client",
  article: "/article",
  user: "/user",
  statique: "/statique",
  expedition: "/expedition",
};

export default urls;
