const stateApp = "redux"; //value = redux or context to change the state management of the app
const designApp = "v1"; //la version du design de l'app for now we have v0:default v1, v2
const date = new Date();
const nameApp = "J.M.L";
const nameAppLong = "J.M.L";
const longName = "Socièté FL";
const welcomeMessage = "connectez-vous ici";
const footer = `© J.M.L - ${date.getFullYear()}. Tous droits réservés`;

export default {
  stateApp,
  designApp,
  nameApp,
  nameAppLong,
  welcomeMessage,
  longName,
  footer,
};
