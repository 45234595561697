import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import { urls, icons } from "../../constant";
import { getRequest } from "../../network/httpRequest";
import { Container } from "../../reusables";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((store) => store.data);
  const [setDataD] = useState([]);

  const getDashboard = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.dashboard}/site`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataD(data);
  };
  useEffect(() => {}, [lang]);
  return (
    <>
      <Container
        icon={icons.homeOutlineTitle}
        pageName={"TABLEAU DE BORD"}
      ></Container>
    </>
  );
};
export default Dashboard;
