import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentTab: {},
  navigDest: "default",
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setTab: (state, { payload }) => {
      state.currentTab = payload;
    },
    setNavig: (state, { payload }) => {
      state.navigDest = payload;
    },
  },
});

export const { setTab, setNavig } = tabSlice.actions;
export default tabSlice.reducer;
