import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  select: {
    optionsList: [],
    selectedOption: {},
    selectedOptions: [],
    showSelect: false,
  },
};

const formSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setSelect: (state, { payload }) => {
      state.select = { ...state.select, ...payload };
    },
  },
});

export const { setSelect } = formSlice.actions;
export default formSlice.reducer;
