import { icons } from "../../constant";
import { Stack, IconButton } from "@mui/material";
const Operation = ({ d, e, v }) => {
  return (
    <Stack direction="row" spacing={1}>
      {e && <IconButton aria-label="editer">{icons.mEdit}</IconButton>}
      {d && (
        <IconButton aria-label="delete" color="error">
          {icons.mDelete}
        </IconButton>
      )}
    </Stack>
  );
};
export default Operation;
