import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { config, menu } from "../../../constant";

const { firstLevel } = menu.menu;

const Navbar = () => {
  const history = useHistory();
  const path = history.location.pathname;
  useEffect(() => {}, []);
  return (
    <div>
      <div className="logo-name">
        <h1>{config.nameAppLong}</h1>
        <span>{config.longName}</span>
      </div>
      <nav>
        <ul className="first-level">
          {firstLevel.map((menus, idx) => {
            return (
              <li
                key={idx}
                className={`${menus.path === path && "active-menu"}`}
              >
                <NavLink to={menus.path}>
                  <span>{menus.icon}</span>
                  <span className="menu-item">{menus.name}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
