import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { config } from "./constant";
// import "./css/main-v1.css";
import "./css/v1.css";
import "./css/v1-el.css";
import App from "./App";
import { store, persistor } from "./global-state/redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";

//MUI integration
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

//decommenter cette ligne si tu vas utiliser le management du global state avec ContextAPI
// import { AppProvider } from "./global-state/context/context";

//all pages

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3799",
    },
    // secondary: {},
    // error: {},
    // warning:{},
    // info:{},
    // success:{},
    // grey:{}
  },
});
root.render(
  <Provider store={store}>
    <Router>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Router>
  </Provider>
);
