import icons from "./icons";
/*
  menu principal
*/
const menu = {
  firstLevel: [
    {
      id: 1,
      name: "tableau de bord",
      icon: icons.homeOutline,
      path: "/dashboard",
    },
    {
      id: 2,
      name: "fournisseurs",
      icon: icons.fournOutline,
      path: "/fournisseurs",
    },
    {
      id: 3,
      name: "clients",
      icon: icons.usersOutline,
      path: "/clients",
    },
    {
      id: 4,
      name: "Expeditions",
      icon: icons.expeditionOutline,
      path: "/expeditions",
    },
    {
      id: 5,
      name: "Comptes",
      icon: icons.compteOutline,
      path: "/comptes",
    },
    {
      id: 6,
      name: "Rapports",
      icon: icons.rapportOutline,
      path: "/rapports",
    },
    // {
    //   id: 7,
    //   name: "général",
    //   icon: icons.usersOutline,
    //   path: "/general",
    // },
  ],
};

/*
  menu tabulations
*/

const tabsClients = [
  {
    id: "1",
    name: "clients principaux",
    description: " ",
    clause: "",
    icon: icons.mainClient,
  },
  {
    id: "2",
    name: "clients secondaires",
    description: " ",
    clause: "",
    icon: icons.secondaireClient,
  },
];

const tabsExpeditions = [
  {
    id: "1",
    name: "EN ROUTE",
    description: " ",
    clause: "",
    icon: icons.mainClient,
  },
  {
    id: "2",
    name: "ARRIVER",
    description: " ",
    clause: "",
    icon: icons.secondaireClient,
  },
  {
    id: "3",
    name: "SUPPRIMER",
    description: " ",
    clause: "",
    icon: icons.secondaireClient,
  },
];
export default {
  menu,
  tabsClients,
  tabsExpeditions,
};
