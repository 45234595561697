import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uniqueID: "",
};

const uniqueSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setUniqueId: (state, { payload }) => {
      state.uniqueID = payload;
    },
  },
});

export const { setUniqueId } = uniqueSlice.actions;
export default uniqueSlice.reducer;
