import { icons } from "../constant";
const configFormLogin = [
  {
    name: "username",
    type: "text",
    value: "",
    icon: icons.user,
    label: "numero de telephone",
  },
  {
    name: "password",
    type: "password",
    value: "",
    icon: icons.pwd,
    label: "mot de passe",
  },
];

const configFormAddFournisseur = [
  {
    name: "name",
    type: "text",
    value: "",
    icon: icons.bank,
    label: "nom fournisseur",
  },
  {
    name: "tel",
    type: "text",
    value: "",
    icon: icons.tel,
    label: "téléphone",
  },
  {
    name: "address",
    type: "text",
    value: "",
    icon: icons.address,
    label: "adresse",
  },
];

const configFormAddClient = [
  {
    name: "name",
    type: "text",
    value: "",
    icon: icons.user,
    label: "nom client",
  },
  {
    name: "tel",
    type: "text",
    value: "",
    icon: icons.tel,
    label: "téléphone",
  },
  {
    name: "address",
    type: "text",
    value: "",
    icon: icons.address,
    label: "adresse",
  },
  {
    name: "isMain",
    type: "select",
    multiple: false,
    value: "",
    icon: icons.type,
    label: "Type de client",
    options: [
      { id: 1, name: "Principal" },
      { id: 2, name: "Secondaire" },
    ],
  },
];
const configFormAddArticleFournisseur = [
  {
    name: "idArticle",
    type: "select",
    multiple: true,
    value: "",
    icon: icons.article,
    label: "chosir article",
    options: [],
  },
];

const configFormAddUser = {
  firstOne: [
    {
      name: "nom",
      type: "text",
      value: "",
      icon: icons.user,
      label: "Nom",
    },
    {
      name: "prenom",
      type: "text",
      value: "",
      icon: icons.user,
      label: "Prenom",
    },
    {
      name: "sexe",
      type: "select",
      multiple: false,
      value: "",
      icon: icons.type,
      label: "Genre",
      options: [
        { id: "M", name: "Masculin" },
        { id: "F", name: "Feminin" },
      ],
    },
    {
      name: "tel",
      type: "text",
      value: "",
      icon: icons.user,
      label: "Téléphone",
    },
  ],
  secondOne: [
    {
      name: "dob",
      type: "date",
      value: "",
      icon: icons.user,
      label: "Date naissance",
    },
    {
      name: "roles_id",
      type: "select",
      multiple: false,
      value: "",
      icon: icons.article,
      label: "Rôle",
      options: [],
    },
    {
      name: "depot_id",
      type: "select",
      multiple: false,
      value: "",
      icon: icons.article,
      label: "Dépôt ou PE ou PR",
      options: [],
    },
    {
      name: "date_debut_service",
      type: "date",
      value: "",
      icon: icons.user,
      label: "Date debut service",
    },
  ],
  thirdOne: [
    {
      name: "is_main",
      type: "select",
      multiple: false,
      value: "",
      icon: icons.type,
      label: "Type",
      options: [
        { id: 1, name: "Principal" },
        { id: 2, name: "Secondaire" },
      ],
    },
    {
      name: "username",
      type: "text",
      value: "",
      icon: icons.user,
      label: "Nom d'utilisateur",
    },
    {
      name: "passwordMain",
      type: "password",
      value: "",
      icon: icons.user,
      label: "Mot de passe ",
    },
    {
      name: "passwordOp",
      type: "password",
      value: "",
      icon: icons.user,
      label: "Mot de passe des operations",
    },
  ],
};
const configFormSearchExpedition = [
  {
    name: "dateArrive",
    type: "date",
    value: "",
    icon: icons.bank,
    label: "Choisir une date",
  },
];
export {
  configFormLogin,
  configFormAddFournisseur,
  configFormAddClient,
  configFormAddArticleFournisseur,
  configFormAddUser,
  configFormSearchExpedition,
};
