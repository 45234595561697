import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, setLoading } from "../global-state/redux";
import { getRequest } from "../network/httpRequest";
const useGetData = (url) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [url]);
  const getData = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(url);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setData(data);
  };
  return [data, getData];
};

export default useGetData;
