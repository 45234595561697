import React from "react";
import { Grid, Chip } from "@mui/material";
import { ContainerSecondPage } from "../shared";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../core-ui";
import { Operation } from "../../reusables";

const DetailsExpeditions = () => {
  const {
    dataAll: {
      plaqueNumero,
      factureNumero,
      telChauffeur,
      dateDepart,
      dateArrivee,
      sourceDepot,
      destinationDepot,
      userSource,
      userDestination,
      clientDestFinale,
      articlesDetails,
      status,
    },
  } = useSelector((store) => store.data);

  return (
    <ContainerSecondPage title={`détail du vehicule ${plaqueNumero}`}>
      <Grid item xs md={4} className="detail-container">
        <h3>information de l'expedition</h3>
        <div className="details">
          <div className="detail-info">
            <p>status </p>
            <p>
              :{" "}
              <Chip
                label={status.name}
                color={status.webColor}
                variant="contained"
              />
            </p>
          </div>
          <div className="detail-info">
            <p>plaque </p>
            <p>: {plaqueNumero}</p>
          </div>
          <div className="detail-info">
            <p>n° facture</p>
            <p>: {factureNumero ? factureNumero : "-"}</p>
          </div>
          <div className="detail-info">
            <p>tél chauffeur</p>
            <p>: {telChauffeur}</p>
          </div>
          <div className="detail-info">
            <p>date départ</p>
            <p>: {dateDepart}</p>
          </div>
          <div className="detail-info">
            <p>date arrivée</p>
            <p>: {dateArrivee ? dateArrivee : "-"}</p>
          </div>
          <div className="detail-info">
            <p>dépot source</p>
            <p>: {sourceDepot ? sourceDepot.nom : "-"}</p>
          </div>
          <div className="detail-info">
            <p>dépot destination</p>
            <p>: {destinationDepot ? destinationDepot.nom : "-"}</p>
          </div>
          <div className="detail-info">
            <p>fait par</p>
            <p>
              : {userSource ? `${userSource.nom} ${userSource.prenom}` : "-"}
            </p>
          </div>
          <div className="detail-info">
            <p>validé par</p>
            <p>
              :{" "}
              {userDestination
                ? `${userDestination.nom} ${userDestination.prenom}`
                : "-"}
            </p>
          </div>
          <div className="detail-info">
            <p>destination finale</p>
            <p>: {clientDestFinale ? clientDestFinale.name : "-"}</p>
          </div>
        </div>
      </Grid>
      <Grid item xs md={8} className="detail-container">
        <h3>liste des articles de l'expedition</h3>
        <ListArticlesExpedition dataArticles={articlesDetails} />
      </Grid>
    </ContainerSecondPage>
  );
};

const ListArticlesExpedition = ({ dataArticles }) => {
  const tableData =
    dataArticles &&
    dataArticles.map((item) => {
      return {
        ...item,
        fournisseur: item.fournisseurDetail ? item.fournisseurDetail.name : "-",
        client: item.clientDetail ? item.clientDetail.name : "-",
        article: item.articleDetail
          ? `(${item.articleDetail.code_article}) ${item.articleDetail.nom_article}`
          : "-",
        qteRecue: item.qteRecue ? item.qteRecue : "-",
        qtePerdue: item.qtePerdue ? item.qtePerdue : "-",
        qteExcedente: item.qteExcedente ? item.qteExcedente : "-",
      };
    });
  const columns = [
    {
      field: "fournisseur",
      headerName: "FORNISSEUR",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "client",
      headerName: "CLIENT",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "article",
      headerName: "ARTICLE",
      flex: 2,
      headerClassName: "datatable-column",
    },
    {
      field: "qte",
      headerName: "QTE ENV",
      flex: 1,
      headerClassName: "datatable-column",
      description: "la quantité envoyée",
    },
    {
      field: "qteRecue",
      headerName: "QTE Re",
      flex: 1,
      headerClassName: "datatable-column",
      description: "la quantité recue apres validation",
    },
    {
      field: "qtePerdue",
      headerName: "QTE Pe",
      flex: 1,
      headerClassName: "datatable-column",
      description: "la quantité perdue apres validation",
    },
    {
      field: "qteExcedente",
      headerName: "QTE Ex",
      flex: 1,
      headerClassName: "datatable-column",
      description: "la quantité excedente apres validation",
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 2,
      headerClassName: "datatable-column",
      renderCell: () => Operation({ d: true, e: true, v: false }),
    },
  ];

  return (
    <DataTable
      data={dataArticles ? tableData : []}
      columns={columns}
      loading={false}
    />
  );
};

export default DetailsExpeditions;
