import React from "react";
import { icons } from "../../../constant";
import { useDispatch } from "react-redux";
import { setNavig } from "../../../global-state/redux";
import { Button } from "../../../core-ui";
import BackPager from "../back-pager/BackPager";

import { Grid } from "@mui/material";
const HeaderPager = ({ btnTitle, configBtn, backButton, children }) => {
  const { showBtn, navigDest } = configBtn;
  const dispatch = useDispatch();
  return (
    <Grid container className="header-pager">
      <Grid item md={2}>
        {backButton && (
          <BackPager goBack={() => dispatch(setNavig("default"))} />
        )}
      </Grid>
      <Grid item md={8}>
        {children}
      </Grid>
      <Grid item md={2} style={{ display: "flex", justifyContent: "flex-end" }}>
        {showBtn && (
          <Button
            type="button"
            variant="contained"
            color="success"
            label={btnTitle}
            fxOnClick={() => dispatch(setNavig(navigDest))}
            icone={icons.mAdd}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderPager;
