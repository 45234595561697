import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  Login,
  Dashboard,
  Clients,
  Fournisseurs,
  Comptes,
  Error,
  Expeditions,
} from "./pages";
import { useSelector } from "react-redux";

const App = () => {
  const { isConnected } = useSelector((store) => store.user);
  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/login" render={() => <Login />} />
      <Route
        exact
        path="/dashboard"
        render={() =>
          isConnected ? <Dashboard /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/clients"
        render={() => (isConnected ? <Clients /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/fournisseurs"
        render={() =>
          isConnected ? <Fournisseurs /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/comptes"
        render={() => (isConnected ? <Comptes /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/expeditions"
        render={() =>
          isConnected ? <Expeditions /> : <Redirect to={"/login"} />
        }
      />
      <Route exact path="*" render={() => <Error />} />
    </Switch>
  );
};

export default App;
