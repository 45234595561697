import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isloading: false,
  isSaved: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isloading = !state.isloading;
    },
    setIsSaved: (state, { payload }) => {
      state.isSaved = payload;
    },
  },
});
export const { setLoading, setIsSaved } = loadingSlice.actions;
export default loadingSlice.reducer;
