import React from "react";
import { icons } from "../../constant";

const Empty = ({ icon, description }) => {
  return (
    <div className="empty">
      {icons.mEmpty}
      <p>{description}</p>
    </div>
  );
};

export default Empty;
