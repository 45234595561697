import React, { useEffect } from "react";
import { icons } from "../../constant";
const msgDisplay = {
  failed: "Echec!",
  success: "Succès",
};

const ErrorPopup = ({ errorsConfig, fxClosePopup }) => {
  const { showError, messageError, typeError } = errorsConfig;
  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        fxClosePopup();
      }, 5000);
    }
  }, [showError]);

  return (
    <div className={`error-popup ${typeError} ${showError ? "enter" : "quit"}`}>
      <div>{icons[typeError]}</div>
      <div>
        <span>{msgDisplay[typeError]}</span>
        <p>{messageError}</p>
      </div>
    </div>
  );
};

export default ErrorPopup;
