import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";

const MenuPager = ({ children, fxOnTabChange, tabsList }) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    fxOnTabChange(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabsList.map((item) => {
              return <Tab label={item.name} value={item.id} key={item.id} />;
            })}
          </TabList>
        </Box>
        {children}
      </TabContext>
    </Box>
  );
};
export default MenuPager;
