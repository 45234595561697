import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const Input = ({ value, icon, type, fxOnchange, name, label, options }) => {
  return (
    <>
      <div className="form-input">
        <TextField
          label={label}
          variant="outlined"
          fullWidth
          type={type}
          autoComplete="off"
          id={name}
          name={name}
          onChange={fxOnchange}
        />
      </div>
    </>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(["text", "password", "number", "date"]),
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  fxOnchange: PropTypes.func.isRequired,
};

export default Input;
