const etats = [
  {
    name: "INACTIF",
    class: "error",
  },
  {
    name: "ACTIF",
    class: "success",
  },
];

const loan = [
  {
    name: "NON",
  },
  {
    name: "OUI",
  },
];

const accountUser = [
  {
    name: "ACTIF",
    class: "success",
  },
  {
    name: "BLOQUER",
    class: "error",
  },
];

export default { etats, loan, accountUser };
