import { useEffect, useReducer, useState } from "react";
import { Chip } from "@mui/material";
import {
  Container,
  Operation,
  MenuPager,
  Form,
  HeaderPager,
} from "../../reusables";
import { DataTable } from "../../core-ui";
import { icons, urls, menu } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { setNavig, setDataAll } from "../../global-state/redux";
import { useGetData } from "../../hooks";
import { DetailsExpeditions } from "../../components";
import {
  defaultValueFormSearchExpedition,
  configFormSearchExpedition,
  reducer,
} from "../../reducers";
const tabs = menu.tabsExpeditions;
const configBtn = {
  showBtn: false,
  navigDest: "add-expedition",
};
const Expeditions = () => {
  const dispatch = useDispatch();
  const { navigDest } = useSelector((store) => store.tab);
  useEffect(() => {
    dispatch(setNavig("default"));
  }, []);
  return (
    <Container icon={icons.expeditionOutlineTitle} pageName={"EXPEDITIONS"}>
      <HeaderPager
        btnTitle="nouveau"
        configBtn={configBtn}
        backButton={navigDest !== "default"}
      />
      <div className="page-content">
        {navigDest === "default" && <ListExpeditions />}
        {navigDest === "detail-expedition" && <DetailsExpeditions />}
      </div>
    </Container>
  );
};

const ListExpeditions = () => {
  const [tabId, setTabId] = useState("1");
  const [paramUrl, setParamUrl] = useState("validate=false");
  const [dateUrl, setDateUrl] = useState("");
  const dispatch = useDispatch();
  let [data] = useGetData(`${urls.expedition}?${paramUrl}`);
  const { isloading } = useSelector((store) => store.loading);
  const { etats } = global;

  let tableData = [];
  tableData =
    data &&
    data.map((item) => {
      return {
        ...item,
        sourceDepot: item.sourceDepot.nom,
        destDepot: item.destinationDepot.nom,
      };
    });

  useEffect(() => {
    const newParamUrl =
      tabId === "1"
        ? "validate=false"
        : tabId === "2" && !dateUrl
        ? `validate=true&dateArrived=${new Date()}`
        : tabId === "2" && dateUrl
        ? `validate=true&dateArrived=${dateUrl}`
        : "inTrash=1";
    setParamUrl(() => {
      return newParamUrl;
    });
  }, [tabId, dateUrl]);
  const columns = [
    {
      field: tabId === "1" ? "dateDepart" : "dateArrivee",
      headerName: tabId === "1" ? "DATE DEPART" : "DATE ARRIVEE",
      flex: 1,
      type: "string",
      description: "",
      headerClassName: "datatable-column",
    },
    {
      field: "plaqueNumero",
      headerName: "PLAQUE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "telChauffeur",
      headerName: "TEL CHAUFFEUR",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "sourceDepot",
      headerName: "SOURCE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "destDepot",
      headerName: "DESTINATION",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => {
        const { status } = e.row;
        return (
          <Chip
            label={status.name}
            color={status.webColor}
            variant="contained"
          />
        );
      },
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: () => Operation({ d: true, e: true, v: false }),
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => (
        <Chip
          label="Détail"
          component="div"
          variant="outlined"
          color="default"
          clickable
          onClick={() => {
            dispatch(setNavig("detail-expedition"));
            dispatch(setDataAll(e.row));
          }}
        />
      ),
    },
  ];

  return (
    <MenuPager fxOnTabChange={(value) => setTabId(value)} tabsList={tabs}>
      <FilterBloc setDateUrl={setDateUrl} />
      <DataTable data={tableData} columns={columns} loading={isloading} />
    </MenuPager>
  );
};

const FilterBloc = ({ setDateUrl }) => {
  const [ConfigForm, dispatchConfig] = useReducer(
    reducer,
    configFormSearchExpedition
  );
  const [ValueForm, dispatchValue] = useReducer(
    reducer,
    defaultValueFormSearchExpedition
  );

  useEffect(() => {
    setDateUrl(ValueForm.dateArrive);
  }, [ValueForm]);

  return (
    <div className="m-top-10px form-expedition">
      <Form
        fields={ConfigForm}
        dispatch={dispatchValue}
        values={ValueForm}
        align="column"
      />
    </div>
  );
};
export default Expeditions;
