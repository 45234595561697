import React, { useEffect, useReducer } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Header, NavBar, Footer } from "../";
import { ErrorPopup } from "../../../core-ui/";
import { useSelector } from "react-redux";
import { closeError } from "../../../global-state/redux";

const Container = ({ pageName, icon, children }) => {
  const configError = useSelector((store) => store.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(closeError());
  }, [pageName]);

  return (
    <>
      <Grid container>
        <Grid
          className="content-left"
          item
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          md={3}
          lg={2}
        >
          <NavBar />
        </Grid>
        <Grid item xs md={9} lg={10} className="content-right">
          <Header pageName={pageName} icon={icon} />
          <main className="container-main">
            <div className="content">{children}</div>
          </main>
          <Footer />
        </Grid>
      </Grid>
      <ErrorPopup
        errorsConfig={configError} //  const errors = { messageError: msg, showError: err, typeError: typeErr,};
        fxClosePopup={() => dispatch(closeError())}
      />
    </>
  );
};

Container.propTypes = {
  pageName: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
};
export default Container;
