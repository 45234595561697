import React, { useEffect, useState } from "react";
import { TabPanel } from "@mui/lab";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTab, setNavig } from "../../global-state/redux";
import { urls, icons, menu, global } from "../../constant";
import { Container, HeaderPager, MenuPager, Operation } from "../../reusables";
import { DataTable } from "../../core-ui";
import { useGetData } from "../../hooks";
import { AddNouveauClient } from "../../components";

const tabs = menu.tabsClients;
const configBtn = {
  showBtn: true,
  navigDest: "add-client",
};
const Clients = () => {
  const dispatch = useDispatch();
  const { currentTab, navigDest } = useSelector((store) => store.tab);

  useEffect(() => {
    dispatch(setTab(tabs[0]));
    dispatch(setNavig("default"));
  }, []);

  return (
    <>
      <Container icon={icons.usersOutlineTitle} pageName={"CLIENTS"}>
        <HeaderPager
          btnTitle="ajouter client"
          configBtn={configBtn}
          backButton={navigDest !== "default"}
        />
        {navigDest === "add-client" && <AddNouveauClient />}
        {(navigDest === "list-client" || navigDest === "default") && (
          <ListClient />
        )}
      </Container>
    </>
  );
};

const ListClient = () => {
  const [tabId, setTabId] = useState("1");
  const [data] = useGetData(`${urls.client}?isMain=${tabId}`);
  const { isloading } = useSelector((store) => store.loading);
  const { etats, loan } = global;
  const columns = [
    {
      field: "name",
      headerName: "NOM",
      flex: 1,
      type: "string",
      description: "le nom du client",
      headerClassName: "datatable-column",
    },
    {
      field: "tel",
      headerName: "TELEPHONE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "address",
      headerName: "ADRESSE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "addLoan",
      headerName: "LOAN",
      flex: 1,
      headerClassName: "datatable-column",
      description:
        "Si oui càd la marchandise de ce client peut être affecté à un autre client secondaire lors de la reception à un point de réception",
      renderCell: (e) => {
        const { addLoan } = e.row;
        return (
          <Chip
            label={loan[addLoan].name}
            color={loan[addLoan].class}
            variant="outlined"
          />
        );
      },
    },
    {
      field: "montantDette",
      headerName: "DETTE (USD)",
      flex: 1,
      headerClassName: "datatable-column",
      description: "la dette du client ",
    },
    {
      field: "isActif",
      headerName: "ETAT",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => {
        const { isActif } = e.row;
        return (
          <Chip
            label={etats[isActif].name}
            color={etats[isActif].class}
            variant="outlined"
          />
        );
      },
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: () => Operation({ d: true, e: true, v: false }),
    },
  ];
  return (
    <MenuPager fxOnTabChange={(value) => setTabId(value)} tabsList={tabs}>
      {/* <TabPanel value={tabId}> */}
      <DataTable data={data} columns={columns} loading={isloading} />
      {/* </TabPanel> */}
    </MenuPager>
  );
};

export default Clients;
