import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TedEmpty from "../empty/Empty";

const DataTable = ({ data, columns, loading }) => {
  return (
    <div
      style={{
        height: data.length < 1 || loading ? 300 : "auto",
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: () =>
            TedEmpty({
              description: "Aucune donnée trouvée",
            }),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        localeText={{
          toolbarColumns: "colonne",
          toolbarFilters: "filtre",
          toolbarExport: "exporter",
          toolbarExportCSV: "Télécharger CSV",
          toolbarExportPrint: "Imprimer",
          toolbarQuickFilterPlaceholder: "Rechercher ici",
          columnsPanelTextFieldLabel: "Rechercher colonne",
          columnsPanelTextFieldPlaceholder: "Nom de la colonne",
          columnsPanelShowAllButton: "Afficher tout",
          columnsPanelHideAllButton: "Fermer tout",
        }}
        loading={loading}
      />
    </div>
  );
};
export default DataTable;
