import React, { useEffect, useState, useReducer } from "react";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDataAll, setNavig } from "../../global-state/redux";
import { urls, icons, global } from "../../constant";
import { Container, HeaderPager, Operation } from "../../reusables";
import {
  AddArticlesAuFournisseur,
  AddNouveauFournisseur,
} from "../../components";
import { DataTable } from "../../core-ui";
import { useGetData } from "../../hooks";

const configBtn = {
  showBtn: true,
  navigDest: "add-fourn",
};
const Fournisseurs = () => {
  const { navigDest } = useSelector((store) => store.tab);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNavig("default"));
  }, []);

  return (
    <>
      <Container icon={icons.fournOutlineTitle} pageName={"FOURNISSEURS"}>
        <HeaderPager
          btnTitle="nouveau"
          configBtn={configBtn}
          backButton={navigDest !== "default"}
        />
        <div className="page-content">
          {navigDest === "add-fourn" && <AddNouveauFournisseur />}
          {(navigDest === "list-fourn" || navigDest === "default") && (
            <ListFournisseurs />
          )}
          {navigDest === "add-fourn-article" && <AddArticlesAuFournisseur />}
        </div>
      </Container>
    </>
  );
};

const ListFournisseurs = () => {
  const dispatch = useDispatch();
  let [data] = useGetData(urls.fournisseur);
  const { isloading } = useSelector((store) => store.loading);
  const { etats } = global;
  useEffect(() => {}, [data]);
  const columns = [
    {
      field: "name",
      headerName: "NOM",
      flex: 1,
      type: "string",
      description: "le nom du fournisseur",
      headerClassName: "datatable-column",
    },
    {
      field: "tel",
      headerName: "TELEPHONE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "address",
      headerName: "ADRESSE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "isActif",
      headerName: "ETAT",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => {
        const { isActif } = e.row;
        return (
          <Chip
            label={etats[isActif].name}
            color={etats[isActif].class}
            variant="outlined"
          />
        );
      },
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: () => Operation({ d: true, e: true, v: false }),
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => (
        <Chip
          label="Articles"
          component="div"
          variant="contained"
          color="secondary"
          clickable
          onClick={() => {
            dispatch(setNavig("add-fourn-article"));
            dispatch(setDataAll(e.row));
          }}
        />
      ),
    },
  ];

  return (
    <div className="tab" style={{ width: "100%" }}>
      <DataTable data={data} columns={columns} loading={isloading} />
    </div>
  );
};
export default Fournisseurs;
