import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DateInput } from "@mui/x-date-pickers/DatePicker";

const DatePicker = ({ label, fxOnchange }) => {
  const [value, setValue] = useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
      <div className="form-input">
        <DateInput
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            fxOnchange(newValue);
          }}
          format="YYYY/MM/DD"
          fullWidth
          label={label}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DatePicker;
