const defaultValueFormLogin = {
  username: "",
  password: "",
};

const defaultValueAddFournisseur = {
  name: "",
  tel: "",
  address: "",
};
const defaultValueAddClient = {
  name: "",
  tel: "",
  address: "",
  isMain: "",
};

const defaultValueAddArticleFournisseur = {
  idArticle: "",
};

const defaultValueAddUser = {
  nom: "",
  prenom: "",
  sexe: "",
  tel: "",
  dob: "",
  photo: "",
  roles_id: "",
  depot_id: "",
  date_debut_service: "",
  date_fin_service: "",
  is_main: 1,
  username: "",
  passwordMain: "",
  passwordOp: "",
};
const defaultValueFormSearchExpedition = {
  dateArrive: "",
};
export {
  defaultValueFormLogin,
  defaultValueAddFournisseur,
  defaultValueAddClient,
  defaultValueAddArticleFournisseur,
  defaultValueAddUser,
  defaultValueFormSearchExpedition,
};
