import { useDispatch } from "react-redux";
import { setError, setLoading, setIsSaved } from "../global-state/redux";
import { postRequest } from "../network/httpRequest";
const usePostData = (url, datas) => {
  const dispatch = useDispatch();

  const postData = async (url, datas) => {
    dispatch(setLoading());
    const requestData = await postRequest(url, "json", datas);
    const { err, msg, data, typeErr } = requestData;
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    return dispatch(setIsSaved(true));
  };

  return [postData];
};
export default usePostData;
