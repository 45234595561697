import {
  combineReducers,
  configureStore,
  applyMiddleware,
} from "@reduxjs/toolkit";
import ErrorReducer from "./error/ErrorSlice";
import LoadingReducer from "./loading/LoadingSlice";
import DataReducer from "./data/DataSlice";
import ModalReducer from "./modal/ModalSlice";
import UserReducer from "./user/UserSlice";
import UniqueReducer from "./unique/UniqueSlice";
import TabReducer from "./tab/TabSlice";
import FormReducer from "./form/FormSlice";

// configuration of session persist
// import sessionStorage from "redux-persist/lib/storage/session";
import localStorage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";

const middlewares = [];
if (process.env.REACT_APP_NODE_ENV === "development") {
  //this for display logger in console when we are developping
  middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["user", "unique"],
};
const rootReducer = combineReducers({
  error: ErrorReducer,
  loading: LoadingReducer,
  data: DataReducer,
  modal: ModalReducer,
  user: UserReducer,
  unique: UniqueReducer,
  tab: TabReducer,
  form: FormReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
