/*
    Ce composant permet de rajouter 
    les articles à un fournisseur selectionne
*/
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePostData, useGetData } from "../../hooks";
import { urls } from "../../constant";
import { Form, Operation } from "../../reusables";
import { Button, DataTable } from "../../core-ui";
import { Grid } from "@mui/material";
import {
  configFormAddArticleFournisseur,
  defaultValueAddArticleFournisseur,
  reducer,
} from "../../reducers";
import { ContainerSecondPage } from "../shared";
const AddArticlesAuFournisseur = () => {
  const { isloading } = useSelector((store) => store.loading);
  const {
    dataAll: { name, id: idFournisseur },
  } = useSelector((store) => store.data);
  const [ValueForm, dispatchValue] = useReducer(
    reducer,
    defaultValueAddArticleFournisseur
  );
  const [ConfigForm, dispatchConfig] = useReducer(
    reducer,
    configFormAddArticleFournisseur
  );
  const [postData] = usePostData();
  const [data] = useGetData(urls.article);

  const add = () => {
    const datas = { ...ValueForm, idFournisseur };
    postData(`${urls.fournisseur}/add-article`, datas);
  };

  useEffect(() => {
    dispatchConfig({
      type: "SET_DATA_SELECT_ARTICLES",
      payload: data,
    });
  }, [data]);
  return (
    <ContainerSecondPage title={`ajouter des articles au fournisseur ${name}`}>
      <Grid item xs md={4}>
        <form>
          <Form
            fields={ConfigForm}
            dispatch={dispatchValue}
            values={ValueForm}
            align="column"
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            label="enregistrer"
            loading={isloading}
            fxOnClick={() => add()}
          />
        </form>
      </Grid>
      <Grid item xs md={8} sx={{ paddingLeft: 6 }}>
        <ListArticlesFournis />
      </Grid>
    </ContainerSecondPage>
  );
};

const ListArticlesFournis = () => {
  const {
    dataAll: { name, id: idFournisseur },
  } = useSelector((store) => store.data);
  const [data, getData] = useGetData(`${urls.fournisseur}/${idFournisseur}`);
  const { isloading, isSaved } = useSelector((store) => store.loading);
  useEffect(() => {
    getData();
  }, [isSaved]);
  let tableData = [];
  const columns = [
    {
      field: "code_article",
      headerName: "CODE ARTICLES",
      flex: 1,
      type: "string",
      description: "le code d'article",
      headerClassName: "datatable-column",
    },
    {
      field: "nom_article",
      headerName: "NOM ARTICLE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: () => Operation({ d: true, e: false, v: false }),
    },
  ];

  tableData =
    data &&
    data.articlesFournis &&
    data.articlesFournis.map(({ article }) => {
      return {
        ...article,
      };
    });

  return (
    <div className="">
      <DataTable
        data={data.articlesFournis ? tableData : []}
        columns={columns}
        loading={isloading}
      />
    </div>
  );
};
export default AddArticlesAuFournisseur;
