import * as React from "react";
import { Box, TextField, MenuItem } from "@mui/material";

const Select = ({ label, name, fxOnchange, options }) => {
  const [val, setVal] = React.useState("");
  const handleChange = (event) => {
    setVal(event.target.value);
    fxOnchange(event.target.value);
  };

  return (
    <Box component="form" noValidate autoComplete="off" className="form-input">
      <div>
        <TextField
          id={name}
          select
          value={val}
          label={label}
          fullWidth
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Box>
  );
};

export default Select;
