import React from "react";
import { Button as Btn } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { Loader } from "..";
import { icons } from "../../constant";

const Button = ({
  type,
  variant,
  color,
  label,
  fxOnClick,
  loading,
  fullWidth,
  size,
  icone,
}) => {
  return !loading ? (
    <Btn
      type={type}
      variant={variant}
      color={color}
      onClick={!loading ? fxOnClick : () => {}}
      size={size}
      fullWidth={fullWidth}
      startIcon={icone}
    >
      <span>{label}</span>
    </Btn>
  ) : (
    <LoadingButton loading variant={variant} fullWidth={fullWidth}>
      Submit
    </LoadingButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
  color: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  fxOnClick: PropTypes.func.isRequired,
};

export default Button;
