const reducer = (state, action) => {
  if (action.type === "SET_FORM_INPUT_VALUE") {
    let { name, value } = action.payload;
    return {
      ...state,
      [name]: value,
    };
  }
  if (action.type === "SET_DATA_SELECT_ARTICLES") {
    const dt = action.payload;
    const newState = state;
    const newDt = dt.map(({ id, code_article, nom_article }) => {
      return {
        id,
        name: `(${code_article}) ${nom_article}`,
      };
    });
    newState[0].options = newDt;

    return [...newState];
  }

  if (action.type === "SET_DATA_STATIQUE") {
    const { roles, depots } = action.payload;
    const newState = state;

    const newRoles = roles.map(({ id, description }) => {
      return {
        id,
        name: description,
      };
    });
    const newDepots = depots.map(({ id, nom }) => {
      return {
        id,
        name: nom,
      };
    });

    newState.secondOne[1].options = newRoles;
    newState.secondOne[2].options = newDepots;

    return {
      ...newState,
    };
  }
  return state;
};

export default reducer;
