import { icons } from "../../../constant";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
const BackPager = ({ goBack }) => {
  return (
    <Grid className="back-pager">
      <Grid
        item
        xs
        width={100}
        onClick={goBack}
        alignItems={"center"}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {icons.back}
        <span>retour</span>
      </Grid>
    </Grid>
  );
};
BackPager.propTypes = {
  goBack: PropTypes.func.isRequired,
};
export default BackPager;
