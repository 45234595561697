import React from "react";
import { Input, SelectMultiple, Select, DatePicker } from "../../core-ui";
import PropTypes from "prop-types";
const Form = ({ fields, dispatch, values, align, multipe }) => {
  return (
    <div className={`form ${align ? align : "column"}`}>
      {fields.map((item, idx) => {
        const { icon, type, name, label, options, accept, multiple } = item;
        return type === "file" ? (
          <></>
        ) : type === "select" && multiple === true ? (
          <div key={idx}>
            <SelectMultiple
              value={values[name]}
              icon={icon}
              type={type}
              name={name}
              label={label}
              options={options}
              fxOnchange={(value) =>
                dispatch({
                  type: "SET_FORM_INPUT_VALUE",
                  payload: {
                    name,
                    value,
                  },
                })
              }
            />
          </div>
        ) : type === "select" && multiple === false ? (
          <div key={idx}>
            <Select
              value={values[name]}
              icon={icon}
              type={type}
              name={name}
              label={label}
              options={options}
              fxOnchange={(value) =>
                dispatch({
                  type: "SET_FORM_INPUT_VALUE",
                  payload: {
                    name,
                    value,
                  },
                })
              }
            />
          </div>
        ) : type === "checkbox" ? (
          <></>
        ) : type === "date" ? (
          <div key={idx}>
            <DatePicker
              label={label}
              fxOnchange={(value) => {
                dispatch({
                  type: "SET_FORM_INPUT_VALUE",
                  payload: {
                    name,
                    value,
                  },
                });
              }}
            />
          </div>
        ) : (
          <div key={idx}>
            <Input
              value={values[name]}
              icon={icon}
              type={type}
              fxOnchange={(e) =>
                dispatch({
                  type: "SET_FORM_INPUT_VALUE",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                })
              }
              name={name}
              label={label}
            />
          </div>
        );
      })}
    </div>
  );
};

Form.propTypes = {
  fields: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  align: PropTypes.oneOf(["row", "column"]).isRequired,
};

export default Form;
