import React, { useReducer, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { usePostData } from "../../hooks";
import {
  defaultValueAddClient,
  configFormAddClient,
  reducer,
} from "../../reducers";
import { urls } from "../../constant";
import { Form } from "../../reusables";
import { Button } from "../../core-ui";

import { setNavig, setIsSaved } from "../../global-state/redux";

const AddNouveauClient = () => {
  const dispatch = useDispatch();
  const { isloading, isSaved } = useSelector((store) => store.loading);
  const [ValueForm, dispatchValue] = useReducer(reducer, defaultValueAddClient);
  const [ConfigForm, dispatchConfig] = useReducer(reducer, configFormAddClient);
  const [postData] = usePostData();

  const add = () => {
    const datas = ValueForm;
    postData(urls.client, datas);
  };
  useEffect(() => {
    if (isSaved) {
      dispatch(setNavig("default"));
      dispatch(setIsSaved(false));
    }
  }, [isSaved]);
  return (
    <Grid container direction={"column"}>
      <Grid item style={{ marginBottom: 20 }}>
        <h2>ajouter un nouveau client</h2>
      </Grid>
      <Grid container>
        <Grid item xs md={4}>
          <Form
            fields={ConfigForm}
            dispatch={dispatchValue}
            values={ValueForm}
            align="column"
          />
          <Button
            type="button"
            variant="contained"
            color="primary"
            label="enregistrer"
            loading={isloading}
            fxOnClick={() => add()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddNouveauClient;
