import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNavig } from "../../global-state/redux";
import { urls, global, icons } from "../../constant";
import { Container, HeaderPager, Operation } from "../../reusables";
import { useGetData } from "../../hooks";
import { DataTable } from "../../core-ui";
import { AddNouveauUser } from "../../components";
const configBtn = {
  showBtn: true,
  navigDest: "add-user",
};
const Comptes = () => {
  const dispatch = useDispatch();
  const { navigDest } = useSelector((store) => store.tab);
  useEffect(() => {
    dispatch(setNavig("default"));
  }, []);
  return (
    <>
      <Container
        icon={icons.compteOutlineTitle}
        pageName={"COMPTES DES UTILISATEURS"}
      >
        <HeaderPager
          btnTitle="nouveau"
          configBtn={configBtn}
          backButton={navigDest !== "default"}
        />

        <div className="page-content">
          {navigDest === "add-user" && <AddNouveauUser />}
          {(navigDest === "list-user" || navigDest === "default") && (
            <ListUsers />
          )}
          {/* {navigDest === "add-fourn-article" && <AddArticlesAuFournisseur />} */}
        </div>
      </Container>
    </>
  );
};

const ListUsers = () => {
  const dispatch = useDispatch();
  let [data] = useGetData(urls.user);
  const { isloading } = useSelector((store) => store.loading);
  const { accountUser } = global;
  console.log(accountUser);
  useEffect(() => {}, [data]);
  let tableUsers = [];
  const columns = [
    {
      field: "nom",
      headerName: "NOM",
      flex: 1,
      type: "string",
      description: "le nom du fournisseur",
      headerClassName: "datatable-column",
    },
    {
      field: "prenom",
      headerName: "PRENOM",
      flex: 1,
      type: "string",
      description: "le nom du fournisseur",
      headerClassName: "datatable-column",
    },
    {
      field: "tel",
      headerName: "TELEPHONE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "role",
      headerName: "ROLE",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "depot",
      headerName: "AFFECTATION",
      flex: 1,
      headerClassName: "datatable-column",
    },
    {
      field: "etat",
      headerName: "ETAT",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => {
        const { etat } = e.row;
        return (
          <Chip
            label={accountUser[etat - 1].name}
            color={accountUser[etat - 1].class}
            variant="contained"
          />
        );
      },
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: () => Operation({ d: true, e: true, v: false }),
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "datatable-column",
      renderCell: (e) => (
        <Chip
          label="Détails"
          component="div"
          variant="outlined"
          color="default"
          clickable
          onClick={() => {
            // dispatch(setNavig("add-fourn-article"));
            // dispatch(setDataAll(e.row));
          }}
        />
      ),
    },
  ];
  tableUsers = data.map((item) => {
    return {
      ...item,
      role: item.roleUser.description,
      depot: item.depotUser.nom,
      etat: item.authUser.status_users_id,
    };
  });
  console.log(tableUsers);

  return (
    <div className="tab" style={{ width: "100%" }}>
      <DataTable data={tableUsers} columns={columns} loading={isloading} />
    </div>
  );
};
export default Comptes;
