import { images } from "../../constant";
import { Chip } from "@mui/material";
const Error = () => {
  return (
    <div className="error-page">
      <div>
        <img src={images.notFound} alt="page not found" className="img" />
      </div>
      <span>oupss!</span>
      <span>page introuvable</span>
      <span>cette ressource est indisponible !!</span>

      <Chip
        label="Retour sur connexion"
        component="a"
        href="/"
        variant="container"
        color="primary"
        clickable
      />
    </div>
  );
};

export default Error;
