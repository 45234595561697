import {
  MdPerson,
  MdLock,
  MdOutlineHome,
  MdPeopleOutline,
  MdConnectWithoutContact,
  MdNotificationsNone,
  MdOutlineAdd,
  MdPersonOff,
  MdHourglassEmpty,
  MdVisibility,
  MdVisibilityOff,
  MdEditNote,
  MdRestoreFromTrash,
  MdArrowBackIosNew,
  MdFoodBank,
  MdOutlinePhone,
  MdOutlineLocationOn,
  MdCancel,
  MdCheckCircle,
  MdArrowDropDown,
  MdCheck,
  MdCheckBoxOutlineBlank,
  MdCheckBox,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdSearch,
  MdOutlineArticle,
  MdOutlineAccessibilityNew,
  MdMessage,
  MdCompareArrows,
  MdAccountCircle,
  MdOutlineReportProblem,
} from "react-icons/md";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

const icons = {
  user: <MdPerson color="#073042" size={18} />,
  pwd: <MdLock color="#073042" size={18} />,
  bank: <MdFoodBank color="#073042" size={18} />,
  tel: <MdOutlinePhone color="#073042" size={18} />,
  address: <MdOutlineLocationOn color="#073042" size={18} />,
  search: <MdSearch color="#073042" size={18} />,
  article: <MdOutlineArticle color="#073042" size={18} />,
  type: <MdOutlineAccessibilityNew color="#073042" size={18} />,

  notification: <MdNotificationsNone color="#0f172a" size={22} />,
  msg: <MdMessage color="#0f172a" size={22} />,
  add: <MdOutlineAdd color="#3c3799" size={15} />,
  empty: <MdHourglassEmpty color="#ff0000" size={35} />,
  eye: <MdVisibility color="#3c3799" size={20} />,
  eyeNo: <MdVisibility color="#3c3799" size={20} />,
  edit: <MdEditNote color="#3c3799" size={20} />,
  trash: <MdRestoreFromTrash color="#ff0000" size={20} />,
  back: <MdArrowBackIosNew color="#3c3799" size={20} />,
  failed: <MdCancel color="#fff" size={30} />,
  success: <MdCheckCircle color="#fff" size={30} />,
  select: <MdArrowDropDown color="#073042" size={25} />,
  close: <MdCancel color="#ff0000" size={30} />,
  done: <MdCheck color="#ff0000" size={20} />,
  checkbox: <MdCheckBox color="#008000" size={25} />,
  unCheckbox: <MdCheckBoxOutlineBlank color="#64748b" size={25} />,
  radioCheck: <MdRadioButtonChecked color="#008000" size={25} />,
  radioUnCheck: <MdRadioButtonUnchecked color="#64748b" size={25} />,

  // main menu
  homeOutline: <MdOutlineHome color="#bfc6d1e1" size={18} />,
  usersOutline: <MdPeopleOutline color="#bfc6d1e1" size={18} />,
  fournOutline: <MdConnectWithoutContact color="#bfc6d1e1" size={18} />,
  expeditionOutline: <MdCompareArrows color="#bfc6d1e1" size={18} />,
  compteOutline: <MdAccountCircle color="#bfc6d1e1" size={18} />,
  rapportOutline: <MdOutlineReportProblem color="#bfc6d1e1" size={18} />,

  // title on page
  homeOutlineTitle: <MdOutlineHome color="#bfc6d1e1" size={25} />,
  usersOutlineTitle: <MdPeopleOutline color="#bfc6d1e1" size={25} />,
  fournOutlineTitle: <MdConnectWithoutContact color="#bfc6d1e1" size={25} />,
  expeditionOutlineTitle: <MdCompareArrows color="#bfc6d1e1" size={25} />,
  compteOutlineTitle: <MdAccountCircle color="#bfc6d1e1" size={25} />,
  rapportOutlineTitle: <MdOutlineReportProblem color="#bfc6d1e1" size={25} />,

  // tabs menu
  mainClient: <MdPerson color="#bfc6d1e1" size={15} />,
  secondaireClient: <MdPersonOff color="#bfc6d1e1" size={15} />,

  // material icons for MUI Materials Fr
  mAdd: <AddIcon />,
  mDelete: <DeleteIcon />,
  mEdit: <EditNoteIcon />,
  mEmpty: <CloudOffIcon />,
  mPersonAdd: <PersonAdd fontSize={"10"} />,
  mSettings: <Settings fontSize="10" />,
  mLogout: <Logout fontSize={"10"} />,
};

export default icons;
