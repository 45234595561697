// import { useDispatch, useSelector } from "react-redux";
// import { setSelect } from "../../global-state/redux";
import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  MenuItem,
  Select as SelcItem,
  Chip,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};
const SelectMultiple = ({ label, fxOnchange, options, name }) => {
  const names = options;
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    fxOnchange(event.target.value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <SelcItem
        labelId={label}
        id={name}
        className="form-input"
        multiple
        value={personName}
        onChange={handleChange}
        fullWidth
        input={<OutlinedInput id={name} label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              const lbl = names.filter((e) => e.id === value);
              return <Chip key={value} label={lbl[0].name} />;
            })}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {names.map(({ id, name }) => (
          <MenuItem
            key={name}
            value={id}
            style={getStyles(name, personName, theme)}
          >
            <Checkbox checked={personName.indexOf(id) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </SelcItem>
    </FormControl>
  );
};

export default SelectMultiple;
