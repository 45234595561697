import React, { useReducer } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Button, ErrorPopup } from "../../core-ui";
import { urls, config } from "../../constant";
import { postRequest } from "../../network/httpRequest";
import "../../translations/i18n";
import {
  reducer,
  defaultValueFormLogin,
  configFormLogin,
} from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  closeError,
  setLoading,
  setLoginUser,
} from "../../global-state/redux";
import { Form } from "../../reusables";

const Login = () => {
  const { nameAppLong, welcomeMessage } = config;
  const configError = useSelector((store) => store.error);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ValueFormLogin, dispatchValue] = useReducer(
    reducer,
    defaultValueFormLogin
  );
  const [ConfigFormLogin, dispatchConfig] = useReducer(
    reducer,
    configFormLogin
  );

  const login = async () => {
    dispatch(setLoading());
    const datas = ValueFormLogin;
    const { err, msg, data, typeErr } = await postRequest(
      urls.login,
      "json",
      datas
    );

    if (err) {
      dispatch(setError({ err, msg, typeErr }));
      dispatch(setLoading());
      return;
    }
    dispatch(setLoading());
    dispatch(setLoginUser({ dataUser: data, isConnected: true }));
    // sessionStorage.setItem("xx_tk", data.token);
    localStorage.setItem("xx_tk", data.token);
    history.push("/dashboard");
  };

  return (
    <>
      <Grid
        container
        className="login"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs sm md={4} className="login-center">
          <div className="header-login">
            <h1>{nameAppLong}</h1>
            <span>{welcomeMessage}</span>
          </div>
          <form>
            <Form
              fields={ConfigFormLogin}
              dispatch={dispatchValue}
              values={ValueFormLogin}
              align="column"
            />
            <Button
              type="button"
              variant="contained"
              color="primary"
              label="se connecter"
              fullWidth={true}
              loading={isloading}
              fxOnClick={() => login()}
            />
          </form>
        </Grid>
      </Grid>

      <ErrorPopup
        errorsConfig={configError}
        fxClosePopup={() => dispatch(closeError())}
      />
    </>
  );
};

export default Login;
