import React, { useReducer, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { usePostData } from "../../hooks";
import {
  defaultValueAddFournisseur,
  configFormAddFournisseur,
  reducer,
} from "../../reducers";
import { urls } from "../../constant";
import { Form } from "../../reusables";
import { Button } from "../../core-ui";
import { setIsSaved, setNavig } from "../../global-state/redux";

const AddNouveauFournisseur = () => {
  const dispatch = useDispatch();
  const { isloading, isSaved } = useSelector((store) => store.loading);
  const [ValueForm, dispatchValue] = useReducer(
    reducer,
    defaultValueAddFournisseur
  );
  const [ConfigForm, dispatchConfig] = useReducer(
    reducer,
    configFormAddFournisseur
  );
  const [postData] = usePostData();

  const add = () => {
    const datas = ValueForm;
    postData(urls.fournisseur, datas);
  };

  useEffect(() => {
    if (isSaved) {
      dispatch(setNavig("default"));
      dispatch(setIsSaved(false));
    }
  }, [isSaved]);
  return (
    <Grid container direction={"column"}>
      <Grid item style={{ marginBottom: 20 }}>
        <h2>ajouter un nouveau fournisseur</h2>
      </Grid>
      <Grid container>
        <Grid item xs md={4}>
          <form>
            <Form
              fields={ConfigForm}
              dispatch={dispatchValue}
              values={ValueForm}
              align="column"
            />
            <Button
              type="button"
              variant="contained"
              color="primary"
              label="enregistrer"
              loading={isloading}
              fxOnClick={() => add()}
            />
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddNouveauFournisseur;
