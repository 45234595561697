import React from "react";
import { Grid } from "@mui/material";
const ContainerSecondPage = ({ title, children }) => {
  return (
    <Grid container direction={"column"}>
      <Grid style={{ marginBottom: 20 }}>
        <h2>{title}</h2>
      </Grid>
      <Grid container>{children}</Grid>
    </Grid>
  );
};

export default ContainerSecondPage;
